<template>
  <v-container fluid class="content-wrapper">
    <v-row row wrap>
      <v-col cols="12">
        <div class="btnbackvoucher" @click="backtoOverview">
          <v-icon left small>mdi-chevron-double-left</v-icon>
          <span class="mt-1">Return to voucher overview</span>
        </div>
      </v-col>
      <v-col cols="12" style="margin-top: -2%; margin-bottom: -2%;">
        <h3 class="title-md">Voucher Detail</h3>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        Date of
        <v-select :items="item_date" item-text="text" item-value="value" v-model="date_of" class="inputtext uppertext"
          color="#1e9ff2" dense outlined></v-select>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        Type
        <v-select :items="item_type" item-text="text" item-value="value" v-model="type" class="inputtext uppertext"
          color="#1e9ff2" dense outlined></v-select>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        From
        <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false" transition="scale-transition"
          offset-y max-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="start_format" placeholder="dd/mm/yyyy" append-icon="mdi-calendar"
              @click:append="start_menu = true" v-bind="attrs" v-on="on" class="inputtext" color="#1e9ff2" outlined
              dense readonly></v-text-field>
          </template>
          <v-date-picker no-title color="#1e9ff2" v-model="start_date" @input="formatStart(start_date)"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        To
        <v-menu ref="end_menu" v-model="end_menu" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="end_format" placeholder="dd/mm/yyyy" append-icon="mdi-calendar"
              @click:append="end_menu = true" v-bind="attrs" v-on="on" class="inputtext" color="#1e9ff2" outlined dense
              readonly></v-text-field>
          </template>
          <v-date-picker no-title color="#1e9ff2" v-model="end_date" :min="min_enddate" :show-current="min_enddate"
            @input="formatEnd(end_date)"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        Status
        <v-select :items="item_status" item-text="text" item-value="value" v-model="status" class="inputtext uppertext"
          color="#1e9ff2" dense outlined multiple placeholder="Multi Select"></v-select>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        <v-btn depressed dark color="#1e9ff2" class="btnshowvoucher" @click="showVoucherList">แสดง</v-btn>
      </v-col>
    </v-row>
    <!-- ------------------------------- -->
    <v-row>
      <v-col cols="12">
        <div class="card card-shadow">
          <v-row row wrap>
            <v-col cols="4"></v-col>
            <v-col cols="8">
              <div class="head-voucher pa-2 mb-3">
                <v-layout>
                  <h3 class="text-voucherno">VOU-123456</h3>
                  <v-spacer></v-spacer>
                  <div class="btnhistory" @click="openHistory">History</div>
                </v-layout>
                <v-layout>
                  <div class="btntext mr-3">
                    <v-icon left small>mdi-file-outline</v-icon>
                    <span class="mt-2">PDF / Print</span>
                  </div>
                  <div class="btntext">
                    <v-icon left small>mdi-email-outline</v-icon>
                    <span class="mt-2">Mail</span>
                  </div>
                </v-layout>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-right-transition">
      <v-card>123</v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";

export default {
  name: "SigninVoucherDetailScreen",
  metaInfo: {
    title: "Voucher Detail",
    titleTemplate: "%s | Aigent"
  },
  data() {
    return {
      itemsPerPageArray: [10, 20, 50],
      page: 1,
      itemsPerPage: 10,
      userToken: "",
      propertyID: "",
      date_of: "",
      type: "",
      status: "",
      start_menu: false,
      start_format: "",
      start_date: "",
      min_startdate: "",
      end_menu: false,
      end_format: "",
      end_date: "",
      min_enddate: "",
      data_loading: false,
      dialog: false,
      item_date: [
        { text: "All", value: "" },
        { text: "Ordered", value: "ordered" },
        { text: "Booked", value: "booked" },
        { text: "Arrival", value: "arrival" }
      ],
      item_type: [
        { text: "All", value: "" },
        { text: "Buy Now", value: "buy now" },
        { text: "Team Purchase", value: "team purchase" }
      ],
      item_status: [
        { text: "Confirm Reservation", value: "confirm reservation" },
        { text: "Paid Voucher", value: "paid voucher" },
        { text: "Pending Voucher", value: "pending voucher" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Expired", value: "expried" }
      ],
      headers: [
        {
          text: "Voucher No.",
          align: "center",
          sortable: true,
          value: "voucher_no",
          width: "7%"
        },
        {
          text: "Order Date",
          align: "center",
          sortable: true,
          value: "order_date",
          width: "13%"
        },
        {
          text: "Guest Name",
          align: "center",
          sortable: true,
          value: "guest_name",
          width: "16%"
        },
        {
          text: "Room Type",
          align: "center",
          sortable: true,
          value: "room_type",
          width: "7%"
        },
        {
          text: "Booking Date",
          align: "center",
          sortable: true,
          value: "booking_date",
          width: "13%"
        },
        {
          text: "Stay Date",
          align: "center",
          sortable: true,
          value: "stay_date",
          width: "13%"
        },
        {
          text: "Total Amount",
          align: "center",
          sortable: true,
          value: "total_amount",
          width: "14%"
        }
      ],
      vouchers: [
        {
          voucher_no: "202101010001",
          order_date: "2021-01-01",
          guest_name: "Aigent Admin",
          person: 1,
          room_type: "Standard Room",
          room_type_color: "badge-color-primary",
          rate_plan: "Room Only",
          booking_date: "2021-01-01",
          stay_date: {
            start: "2021-02-14",
            end: "2021-02-16"
          },
          total_amount: 1900,
          team_purchase: true
        },
        {
          voucher_no: "202101010002",
          order_date: "2021-01-03",
          guest_name: "Martin Aigent",
          person: 4,
          room_type: "Family Room",
          room_type_color: "badge-color-info",
          rate_plan: "Breakfast Included",
          booking_date: "2021-01-01",
          stay_date: {
            start: "2021-01-01",
            end: "2021-01-05"
          },
          total_amount: 4500,
          team_purchase: false
        }
      ]
    };
  },
  created() {
    // EventBus.$emit('loadingtillend')
    this.userToken = this.$route.params.userToken;
    this.propertyID = this.$route.params.propertyId;
  },
  mounted() {
    // this.getVoucherList()
  },
  methods: {
    async getVoucherList() {
      var tmpItems = [];
      try {
        const url = document.URL;
        const token = url.split("/")[5];

        const res = await this.axios.get(
          process.env.VUE_APP_API +
          `/property?_id=${this?.propertyID ||
          this?.$route?.params?.propertyId}` +
          "&type=" +
          this.type +
          "&status=" +
          this.status +
          "&date_of=" +
          this.date_of +
          "&start_date=" +
          this.start_date +
          "&end_date=" +
          this.end_date,
          {
            headers: {
              Authorization: "Bearer " + token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                this?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          // console.log("voucher list", res.data);
          tmpItems = res.data.result;
        }
      } catch (error) {
        console.dir(error.response);
      } finally {
        // if (!tmpItems?.length) {
        //   swal(
        //     this.$t("Alert.warn_title"),
        //     this.$t("Alert.vouchernofound"),
        //     this.$t("Alert.warn_label"),
        //     {
        //       button: false,
        //       timer: 3000
        //     }
        //   );
        // }
        EventBus.$emit("endloading");
        this.data_loading = false;
      }
    },
    showVoucherList() {
      this.vouchers = [];
      this.data_loading = true;
      this.getVoucherList();
    },
    openHistory() {
      this.dialog = true;
    },
    formatStart(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.start_format = `${day}/${month}/${year}`;
      this.start_menu = false;
      var current = new Date(date);
      this.min_enddate = new Date(
        current.getTime() + 86400000
      ).toLocaleDateString("en-CA");
    },
    formatEnd(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.end_format = `${day}/${month}/${year}`;
      this.end_menu = false;
    },
    backtoOverview() {
      this.$router
        .push({
          name: "SigninVoucherScreen",
          params: {
            userToken: this?.userToken,
            propertyId: this?.propertyID
          }
        })
        .catch(() => { });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.btnbackvoucher {
  cursor: pointer;
  font-size: 12px !important;
}

.btntext {
  cursor: pointer;
  font-size: 14px !important;
}

.text-voucherno {
  font-size: 21px !important;
  font-weight: bold;
}

.btnhistory {
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 600;
}

.btnshowvoucher {
  border-radius: 5px;
  border-color: #1e9ff2 !important;
  background-color: #1e9ff2 !important;
  box-shadow: 0px 1px 20px 1px rgb(30 159 242 / 60%);
  position: absolute;
  bottom: 12px;
  height: 40px;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  // height: 550px;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.text-info {
  color: #1e9ff2 !important;
  font-size: 14px;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
}

.text-subinfo {
  color: #6b6f82 !important;
  font-size: 11px !important;
}

.text-normal {
  font-size: 14px !important;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
  font-style: normal;
  color: #111111;
}

.text-error {
  color: #ff4961 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-badge {
  font-size: 11px !important;
  color: #fff;
}

.badge-style {
  display: inline-block;
  padding: 0.35em 0.4em;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-color-info {
  background-color: #1e9ff2;
}

.badge-color-primary {
  background-color: #666ee8;
}
</style>